<template>
	<div class="block-inner">
		<ul>
			<li v-for="(file, index) in files" :key="index">
				<a class="inline-label" :href="file.url" target="_blank" rel="noopener noreferrer">
					<span><IconFilePdf :width="24" :height="24" /></span>
					<div>{{ file.name }}</div>
				</a>
			</li>
		</ul>
	</div>
</template>

<script>
	import ObjectStore from '../../inc/objectStore';
	import IconFilePdf from '../icons/IconFilePdf';

	export default {
		name: 'BlockFileList',
		components: {
			IconFilePdf
		},
		props: {
			block: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				files: []
			};
		},
		created() {
			if(this.block.content.files && this.block.content.files.length) {
				for(const file of this.block.content.files) {
					ObjectStore.getFileUrl(file).then(url => {
						this.files.push({
							name: file.name,
							url
						});
					});
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	h2, .h2 {
		margin-bottom: 0.5em;
	}

	.inline-label {
		margin-bottom: $default_padding / 2;
	}

	.block-inner {
		& > ul {
			display: inline-block;
			list-style-type: none;
			padding-left: 0;

			a {
				text-decoration: none;
				color: $color__text;
			}
		}
	}

	.inline-label {
		transition: box-shadow .2s ease;

		&:hover {
			box-shadow: $box_shadow;
		}
	}
</style>